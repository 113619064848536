import { FC, memo, useMemo, useState } from "react";
import { FieldValues } from "react-hook-form";
import { AutocompleteElement } from "react-hook-form-mui";
import {
  Box,
  capitalize,
  Grid,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { ReactComponent as RollStabilitySensorDark } from "../../../../../assets/svgs/rollStabilitySensorDark.svg";
import { ReactComponent as RollStabilitySensorLight } from "../../../../../assets/svgs/rollStabilitySensorLight.svg";
import { PAGE_SNACKBAR } from "../../../../../constants";
import { useAppContext } from "../../../../../context/AppContext";
import {
  SensorProfileResult,
  ProfileConfigProperty,
  SensorProfileConfigType,
  UpdateOrganizationSensorProfileInput,
} from "../../../../../graphql/operations";
import Drawer from "../../../../../shared/components/Drawer";
import DrawerActions from "../../../../../shared/components/Drawer/DrawerActions";
import DrawerContent from "../../../../../shared/components/Drawer/DrawerContent";
import DrawerFooter from "../../../../../shared/components/Drawer/DrawerFooter";
import DrawerHeader from "../../../../../shared/components/Drawer/DrawerHeader";
import { useFormTheme } from "../../../../../shared/hooks/theme/useFormTheme";
import { useAvailableOrgs } from "../../../../../shared/hooks/useAvailableOrgs";
import { useSensorProfilesApi } from "../../../hooks/useSensorProfilesApi";
import { DrawerType, RollStabilityDrawers } from "../profileUtils";
import DeleteProfileDialog from "./shared/components/DeleteProfileDialog";
import { ProfilesDrawerMainForm } from "./shared/components/ProfilesDrawerMainForm/ProfilesDrawerMainForm";
import { useProfileDrawerMainForm } from "./shared/hooks/useProfileDrawerMainForm";
import {
  RollStabilityValues,
  useRollStabilityForm,
} from "./shared/hooks/useRollStabilityDrawerForm";

export type RollStabilityDrawerProps = {
  sensorProfileData?: SensorProfileResult;
  type: DrawerType;
  isOpen: boolean;
  onClose: (isOpen: boolean) => void;
  onProfileMutation: () => void;
};

const RollStabilityDrawer: FC<RollStabilityDrawerProps> = ({
  type,
  sensorProfileData,
  onClose,
  isOpen,
  onProfileMutation,
}) => {
  const [deleteRollStabilityProfilePopup, setDeleteRollStabilityProfilePopup] =
    useState(false);
  const {
    dispatch,
    state: { theme },
  } = useAppContext();
  const formTheme = useFormTheme();

  const isLightTheme = theme.theme === "light";
  const svgIconSettings = {
    width: "2.5rem",
    height: "2.5rem",
    display: "block",
    marginTop: "0.75rem",
  };
  // Fetch & prepare organization options
  const availableOrgs = useAvailableOrgs();
  const isEdit = type === RollStabilityDrawers.Edit;

  const currentOrgName = useMemo(() => {
    // Find & show the current organization of the sensor in the dropdown
    if (sensorProfileData?.orgId && isEdit) {
      const foundOrg = availableOrgs.find(
        (org) => org._id.toString() === sensorProfileData.orgId
      );

      return foundOrg?._id;
    }

    return "";
  }, [availableOrgs, isEdit, sensorProfileData]);
  const rollStabilityOptions = Object.values(RollStabilityValues).map(
    (value) => ({
      id: value,
      label: value,
    })
  );
  // Main Form is always static & will constist of same fields for every sensor
  const { form: rollStabilityMainForm } = useProfileDrawerMainForm({
    name: sensorProfileData?.name ?? "",
    orgName: currentOrgName,
    default: sensorProfileData?.default ?? false,
  });

  // roll stability form
  const { form: rollStabilityForm } = useRollStabilityForm(
    sensorProfileData?.configuration?.rollStability?.sudden &&
      sensorProfileData?.configuration?.rollStability?.continuous
      ? {
          sudden:
            sensorProfileData.configuration.rollStability.sudden.toLowerCase() as RollStabilityValues,
          continuous:
            sensorProfileData.configuration.rollStability.continuous.toLowerCase() as RollStabilityValues,
        }
      : {}
  );

  const handleRollStabilityProfileCreateSuccess = () => {
    onProfileMutation();
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Success!",
        text: "Roll Stability Sensor Profile Created Successfully!",
        severity: "success",
      },
    });
  };

  const handleRollStabilityProfileCreateError = () => {
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Sensor Profile Creation Failed",
        text: "Something Went Wrong.",
        severity: "error",
        onClose: () => {},
      },
    });
  };

  const handleRollStabilityProfileUpdateSuccess = () => {
    onProfileMutation();
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Success!",
        text: "Roll Stability Sensor Profile Updated Successfully!",
        severity: "success",
      },
    });
  };

  const handleRollStabilityProfileUpdateError = () => {
    onProfileMutation();
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Sensor Profile Update Failed",
        text: "Something Went Wrong.",
        severity: "error",
        onClose: () => {},
      },
    });
  };

  const handleRollStabilityProfileDeleteSuccess = () => {
    onProfileMutation();
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Success!",
        text: "Roll Stability Sensor Profile Deleted Successfully!",
        severity: "success",
      },
    });
  };

  const handleRollStabilityProfileDeleteError = () => {
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Sensor Profile Deletion Failed",
        text: "Something Went Wrong.",
        severity: "error",
        onClose: () => {},
      },
    });
  };

  const {
    updateSensorProfile,
    isLoadingUpdateSensorProfile,
    deleteSensorProfile,
    isSuccessDeleteSensorProfile,
    isLoadingDeleteSensorProfile,
    createSensorProfile,
    isLoadingCreateSensorProfile,
  } = useSensorProfilesApi({
    createSensorProfileOnSuccess: handleRollStabilityProfileCreateSuccess,
    createSensorProfileOnError: handleRollStabilityProfileCreateError,
    updateSensorProfileOnSuccess: handleRollStabilityProfileUpdateSuccess,
    updateSensorProfileOnError: handleRollStabilityProfileUpdateError,
    deleteSensorProfileOnSuccess: handleRollStabilityProfileDeleteSuccess,
    deleteSensorProfileOnError: handleRollStabilityProfileDeleteError,
  });

  const handleSubmitClick = async () => {
    const mainFormData: FieldValues = rollStabilityMainForm.getValues();
    const rollStabilityFormData: FieldValues = rollStabilityForm.getValues();
    const { orgName, name, default: isDefault } = mainFormData;
    const RollStabilityConfig = {
      rollStability: {
        continuous: capitalize(rollStabilityFormData.continuous),
        sudden: capitalize(rollStabilityFormData.sudden),
      },
    };

    if (type === RollStabilityDrawers.Create) {
      createSensorProfile({
        name,
        configuration: RollStabilityConfig,
        default: isDefault ?? false,
        orgId: orgName ?? "",
      });
    } else if (isEdit) {
      const updateSensorProfilePayload: Record<string, any> = {
        _id: sensorProfileData?._id,
        type: sensorProfileData?.type,
        orgId: orgName,
        name,
        default: isDefault,
        configuration: RollStabilityConfig,
      };

      updateSensorProfile({
        ...updateSensorProfilePayload,
      } as UpdateOrganizationSensorProfileInput);
    }
  };

  const handleDeleteProfile = () => {
    if (isLoadingDeleteSensorProfile || isSuccessDeleteSensorProfile) {
      return;
    }

    deleteSensorProfile({ _id: sensorProfileData?._id ?? "" });
  };

  const toggleDeleteProfilePopup = () => {
    setDeleteRollStabilityProfilePopup(!deleteRollStabilityProfilePopup);
  };

  const handleClose = () => onClose(false);

  const headerText = `${isEdit ? "Edit" : "Create"} Profile`;

  const isLoadingOrUpdating =
    isLoadingCreateSensorProfile ||
    isLoadingUpdateSensorProfile ||
    isLoadingDeleteSensorProfile;
  const isInvalid =
    isLoadingOrUpdating || !rollStabilityMainForm.formState.isValid;
  const isEditAndPristine =
    isEdit &&
    !rollStabilityMainForm.formState.isDirty &&
    !rollStabilityForm.formState.isDirty;

  return (
    <Drawer
      isOpen={isOpen}
      onRequestClose={handleClose}
      testId="roll-stability-profile-drawer"
    >
      <DrawerHeader text={headerText} onClose={handleClose} />

      <DrawerContent>
        <Box className="h-full flex flex-col justify-between">
          <Box>
            <ProfilesDrawerMainForm
              form={rollStabilityMainForm}
              disabled={isLoadingCreateSensorProfile}
              isEdit={isEdit}
            />

            <Box className="px-6" data-testid="profiles-drawer-battery-slider">
              <Typography sx={{ fontWeight: "bold" }}>
                Roll Stability Settings
              </Typography>
              {isLightTheme ? (
                <RollStabilitySensorDark style={svgIconSettings} />
              ) : (
                <RollStabilitySensorLight style={svgIconSettings} />
              )}
            </Box>
            <ThemeProvider theme={formTheme}>
              <Grid
                className="noTopPaddingDrawerSection"
                direction="column"
                container
              >
                <Grid item>
                  <AutocompleteElement
                    data-testid="level1Autocomplete"
                    matchId={true}
                    control={rollStabilityForm.control}
                    rules={{ required: true }}
                    label="Level 1 - Sudden"
                    name="sudden"
                    options={rollStabilityOptions.map((option) => ({
                      ...option,
                      label: capitalize(option.label),
                    }))}
                  />
                </Grid>

                <Grid item>
                  <AutocompleteElement
                    data-testid="level2Autocomplete"
                    matchId={true}
                    control={rollStabilityForm.control}
                    rules={{ required: true }}
                    label="Level 2 - Continuous"
                    name="continuous"
                    options={rollStabilityOptions.map((option) => ({
                      ...option,
                      label: capitalize(option.label),
                    }))}
                  />
                </Grid>
              </Grid>
            </ThemeProvider>
          </Box>

          <DrawerActions
            cancelBtnTestId="btn-cancel-roll-stability-profile"
            deleteBtnTestId="btn-delete-roll-stability-profile"
            showDeleteBtn={isEdit}
            disabled={isLoadingOrUpdating}
            onCancel={handleClose}
            onDelete={toggleDeleteProfilePopup}
          />

          {isEdit && deleteRollStabilityProfilePopup && (
            <DeleteProfileDialog
              isOpen={deleteRollStabilityProfilePopup}
              onClose={toggleDeleteProfilePopup}
              isLoading={isLoadingDeleteSensorProfile}
              onClick={handleDeleteProfile}
              testId="roll-stability-profile-delete-dialog"
            />
          )}
        </Box>
      </DrawerContent>

      <DrawerFooter
        text={
          isLoadingCreateSensorProfile || isLoadingUpdateSensorProfile
            ? "Saving..."
            : "Save"
        }
        disabled={isInvalid || isEditAndPristine}
        testId="roll-stability-profiles-drawer-submit-btn"
        submit={handleSubmitClick}
      />
    </Drawer>
  );
};

export default memo(RollStabilityDrawer);
